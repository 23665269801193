<template>
  <button @click="$emit('click', $event)" class="arrow-button">
    <template v-if="loading">
      <i class="el-icon-loading"/>
    </template>
    <template v-else>
      <span>{{ title }}</span>
      <img src="@/assets/img/button/icon-arrow-white.svg" alt="">
    </template>
  </button>
</template>

<script>
export default {
  name: "arrow-button",
  props: {
    title: { type: String, default: 'Записаться на курс' },

    loading: { type: Boolean, default: false }
  }
}
</script>

<style scoped lang="scss">
.arrow-button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 269px;
  height: 64px;

  background: #1454F2;
  border-radius: 100px;

  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #FFFFFF;

  cursor: pointer;
  transition: 200ms;

  @media screen and (max-width: 350px) {
    width: 240px;
  }

  > img {
    margin-left: 5px;
  }

  &:hover {
    background-color: #4072ef;
  }
}
</style>
