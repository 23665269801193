<template>
  <div class="program">
    <program-content/>
    <course-progress/>
  </div>
</template>

<script>
import ProgramContent from "@/app/user/courseProgram/program/programContent/ProgramContent";
import CourseProgress from "@/app/user/courseProgram/program/progress/CourseProgress";

export default {
  name: "program",
  components: { ProgramContent, CourseProgress }
}
</script>

<style scoped lang="scss">
.program {
  display: flex;

  padding-top: 30px;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
}
</style>
