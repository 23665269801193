import {progressRepository} from "./progress.repository";

class ProgressService {
    async getProgressByCourse(courseId) {
        try {
            const progress = await progressRepository.getProgressByCourse(courseId)
            return progress
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getTypesProgressByCourse(courseId) {
        try {
            const progress = await progressRepository.getTypesProgressByCourse(courseId)
            return progress
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}

export const progressService = new ProgressService()