import http from "@/app/user/config/user-axios.config";

class ProgressRepository {
    async getProgressByCourse(courseId) {
        const response = await http.get(`/spa/courses/${courseId}/progress`)
        return response.data
    }

    async getTypesProgressByCourse(courseId) {
        const response = await http.get(`/spa/lessons/${courseId}/progress`)
        return response.data
    }
}
export const progressRepository = new ProgressRepository()
