<template>
    <div class="progress">
      <h3>Прогресс</h3>
      <div class="progress__content">
        <certificate-progress
            :value="totalProgress"
            :certificateAvailable="isCertificateAvailable"
        />
      </div>
    </div>
</template>

<script>
import CertificateProgress from "@/app/user/courseProgram/program/progress/CertificateProgress";
import {progressService} from "./progress.service";

export default {
  name: "progress",
  components: {CertificateProgress},
  data() {
    return {
      totalProgress: 0,
      typesProgress: [],

      init: false
    }
  },
  computed: {
    courseId() {
      return Number(this.$route.params.courseId)
    },
    isCertificateAvailable() {
      return this.$store.state.myCourses.courses.find(course => course.id === this.courseId)?.certificateAvailable
    }
  },
  async created() {
    await progressService.getProgressByCourse(this.courseId).then(data => {
      this.totalProgress = Math.round(data.progress * 100)
    })

    this.init = true
  }
}
</script>

<style scoped lang="scss">
.progress {
  width: 50%;
  padding-left: 90px;
  box-sizing: border-box;

  @media screen and (max-width: 1200px) {
    padding-left: 25px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    order: 0;
  }

  h3 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    color: #000000;
  }

  &__content {
    width: 100%;

    .circles-wrapper {
      margin-top: 16px;

      @media screen and (max-width: 768px) {
        display: flex;

        .item {
          margin-left: 20px;
          margin-right: 20px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      @media screen and (max-width: 620px) {
        display: none;
      }

    }

  }

}
</style>
