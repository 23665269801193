<template>
  <div class="certificate-progress">

    <div class="first">
      <div class="label">{{ value }}%</div>
      <el-progress
          status="success"
          color="#4EE1A3"
          :text-inside="false"
          :stroke-width="24"
          :percentage="value"/>
      <div v-if="certificateAvailable" class="certificate-progress__description">
        Поздравляем! Вы прошли курс и можете скачать сертификат
      </div>
      <div v-else class="certificate-progress__description">
        Еще немного и вы дойдете до цели
      </div>
    </div>
    <div class="certificate-progress__download">
      <div class="certificate-progress__download-icon"
           :class="{'certificate-progress__download-icon--active': certificateAvailable }">
        <img src="@/assets/img/user/course-program/icon-certificate.svg" alt="">
      </div>

      <el-tooltip placement="top" effect="light" popper-class="download-certificate">
        <div slot="content">Скачать сертификат</div>
        <div class="certificate-progress__download-btn"
             :class="{'certificate-progress__download-btn--active': certificateAvailable }"
             @click="downloadCertificate">
          <img src="@/assets/img/user/course-program/icon-download.svg" alt="">
        </div>
      </el-tooltip>

    </div>

  </div>
</template>

<script>
import imgToSvg from "../../../../../assets/js/imgToSvg";
import {coursesService} from "../../../courses/courses.service";

export default {
  name: "certificate-progress",
  props: {
    value: {type: Number, default: 0},
    certificateAvailable: {type: Boolean, default: false}
  },
  mounted() {
    imgToSvg('.certificate-progress__download-icon img')
  },
  computed: {
    courseId() {
      return Number(this.$route.params.courseId)
    },
  },
  methods: {
    downloadCertificate() {
      if(this.certificateAvailable)
        coursesService.downloadCertificate(this.courseId)
    }
  }
}
</script>

<style scoped lang="scss">
.certificate-progress {
  display: flex;
  align-items: flex-start;

  transition: 0.2s;
  @media screen and (max-width: 400px) {
    flex-direction: column;
  }

  .first {
    width: 80%;
    margin-right: 20px;

    transition: 0.2s;
    @media screen and (max-width: 400px) {
      width: 100%;
    }
  }

  &__download {
    display: flex;
    align-items: center;

    padding-top: 28px;

    transition: 0.2s;
    @media screen and (max-width: 400px) {
      padding-top: 20px;
    }
  }

  &__download-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 32px;
    min-width: 32px;
    height: 32px;

    background: #D6D6D6;
    border-radius: 100%;
    cursor: pointer;

    transition: 0.2s;

    &--active {
      background: #407BFF;
    }
  }

  &__download-icon {
    width: 26px;
    height: 24px;

    margin-right: 10px;
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 32px;
  }

  &__description {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #373737;

    margin-top: 6px;
  }
}

</style>

<style lang="scss">
.certificate-progress {
  &__download-icon {
    &--active {
      svg path {
        fill: #404040;
        transition: all .1s ease-in;
      }
    }
  }

  .el-progress-bar {
    padding-right: 0 !important;
  }

  .el-progress__text {
    display: none;
  }
}

.el-tooltip__popper.is-light.download-certificate {
  border: 1px solid #1454F2;
  .popper__arrow{
    bottom: -6.5px;
    border-top-color: #1454F2;
  }
}
.el-tooltip__popper.download-certificate {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #373737;
}
</style>
