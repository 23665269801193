<template>
  <router-link class="lesson" :to="`/course/${courseId}/lessons/${lesson.id}`">
    <div class="lesson__icon">
<!--      <img v-if="lesson.type === 'video'" src="../../../../../assets/img/courses/program/media-file.svg" alt="">-->
      <img src="../../../../../assets/img/courses/program/text-file.svg" alt="">
<!--      <img src="../../../../../assets/img/courses/program/practical-file.svg" alt="">-->
    </div>
    <div class="lesson__name">
      {{ lesson.name }}
    </div>
    <div v-if="lesson.isCompleted" class="lesson__status">
      <i class="el-icon-check"/>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "lesson",
  props: {
    lesson: Object
  },
  computed:{
    courseId() {
      return this.$route.params.courseId
    },
  },
  methods: {
    handleClickLesson() {
      this.router
    }
  }
}
</script>

<style scoped lang="scss">
.lesson {
  display: flex;
  align-items: center;

  margin-top: 5px;
  margin-bottom: 5px;

  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  text-decoration: none;

  width: 100%;
  height: 50px;

  border: 1px solid #C4C4C4;
  border-radius: 50px;

  cursor: pointer;

  &__icon {
    height: 20px;
  }

  &__name {
    margin-left: 30px;
    margin-right: 10px;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    width: 32px;
    min-width: 32px;
    height: 32px;

    border-radius: 50%;

    background-color: #4EE1A3;
    color: #FFFFFF;
  }

}
</style>
