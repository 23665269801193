<template>
  <div class="program-content" v-loading="loading">
    <h3>Программа курса</h3>
    <div class="program-content__content">
      <div class="program-content__theme"
           v-for="item in themes" :key="item.id">
        <div class="program-content__theme--title">
          {{ item.name }}
        </div>
        <lesson v-for="(lesson,index) in item.lessons"
                :key="index"
                :lesson="lesson"/>
      </div>
    </div>
  </div>
</template>

<script>
import Lesson from "@/app/user/courseProgram/program/programContent/Lesson";
import {lessonsService} from "../../../lessonsCourse/lessonsCourse.service";

export default {
  name: "program-content",
  components: { Lesson },
  data() {
    return {
      loading:true,
      themes: []
    }
  },
  computed:{
    courseId() {
      return this.$route.params.courseId
    },
  },
  created() {
    lessonsService.getInfoThemes(this.courseId).then(data => {
      this.themes = data

      this.loading = false
    })
  },
}
</script>

<style scoped lang="scss">
.program-content {
  width: 50%;

  &__theme--title {
    //font-size: 20px;
    //font-weight: 600;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    order: 1;
    padding-top: 16px;
  }

  h3 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    color: #000000;
  }

  &__content {
    padding-top: 16px;
  }

}
</style>
