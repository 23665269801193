<template>
  <div v-if="course && course.info" class="info">
    <course-header-info
        :course="course"
        :courseInfo="course.info"
        :show-btn-buy="false"/>
<!--    <course-description-info-->
<!--        :tasks-count="course.tasksCount"-->
<!--        :videos-count="course.videosCount"/>-->
    <course-study-info
        :learning-topics="course.info.learningTopics"/>
<!--    <course-author-info-->
<!--        :author="course.info.author"/>-->
<!--    <course-author-certificates-info-->
<!--        :photos="course.info.author.certificatesPhoto"/>-->
    <course-certificate
        :certificate-template-id="course.certificateTemplateId"/>
  </div>
  <div v-else v-loading="true" class="info__preloader"></div>
</template>

<script>
import CourseHeaderInfo from "@/app/user/courseInfo/components/CourseHeaderInfo";
// import CourseDescriptionInfo from "@/app/user/courseInfo/components/CourseDescriptionInfo";
import CourseStudyInfo from "@/app/user/courseInfo/components/CourseStudyInfo";
import CourseCertificate from "@/app/user/courseProgram/info/components/CourseCertificate";

import {coursesService} from "@/app/user/courses/courses.service";

export default {
  name: "info",
  components: {
    CourseHeaderInfo,
    // CourseDescriptionInfo,
    CourseStudyInfo,
    CourseCertificate
  },
  computed: {
    courseId() {
      return Number.parseInt(this.$route.params.courseId)
    },
    course() {
      return this.$store.state.courses.courses.find(item => item.id === this.courseId)
    }
  },
  mounted() {
    coursesService.getCourseInfo(this.courseId)
  }
}
</script>

<style scoped lang="scss">
.info {
  margin-top: 32px;

  &__title {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    margin-bottom: 16px;

    @media screen and (max-width: 980px) {
      display: none;
    }
  }
  &__preloader {
    width: 100%;
    height: calc(100vh - 250px);
  }

}
</style>
<style lang="scss">
.info {
  .admin-course__info-description {
    margin-bottom: 20px;
  }
  .admin-course__wrapper-info {
    min-height: max-content;
  }
  .admin-course__info-logo-img {
    min-height: max-content;
  }

}
</style>
