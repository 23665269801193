<template>
  <div class="course-program">
    <div class="course-program__header">
      <div class="title">
        <span v-if="course && mode === 'PROGRAM'">{{ course.name }}</span>
      </div>
      <div v-if="windowWidth > 1200 && course.progress < 1" class="button">
        <arrow-button @click="$router.push(`/course/${courseId}/lessons/${null}`) "
                      title="Продолжить"/>
      </div>
    </div>

    <div class="course-program__nav">
      <button :class="{'active': mode === 'PROGRAM'}"
              @click="setMode('PROGRAM')">Программа
      </button>
      <button :class="{'active': mode === 'ABOUT'}"
              @click="setMode('ABOUT')">О курсе
      </button>
      <div class="course-program__nav-animation"
           :class="{'course-program__nav-animation--right': mode === 'ABOUT'}"/>
    </div>

    <div class="course-program__content">
      <transition name="el-fade-in-linear" mode="out-in">
        <program v-if="mode === 'PROGRAM'"/>
        <info v-else/>
      </transition>
    </div>

    <img class="course-program__icon-pen" src="@/assets/img/user/course-program/icon-pen.svg" alt="">
    <img class="course-program__ellipse-right" src="@/assets/img/user/course-program/ellipse-right.svg" alt="">
    <img class="course-program__ellipse-left" src="@/assets/img/user/course-program/ellipse-left.svg" alt="">

  </div>
</template>

<script>
import ArrowButton from "@/components/buttons/ArrowButton";
import Program from "@/app/user/courseProgram/program/Program";
import Info from "@/app/user/courseProgram/info/Info";

export default {
  name: "course-program",
  components: {ArrowButton, Program, Info},
  data() {
    return {
      mode: 'PROGRAM'
    }
  },
  computed: {
    course() {
      return this.$store.state.myCourses.courses.find(item => item.id === this.courseId)
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    courseId() {
      return Number.parseInt(this.$route.params.courseId)
    }
  },
  mounted() {
    if(!this.course)
      this.$router.replace('/404')
  },
  methods: {
    setMode(mode) {
      this.mode = mode
    }
  }
}
</script>

<style scoped lang="scss">
.course-program {
  padding-top: 30px;
  box-sizing: border-box;
  width: 100%;

  transition: 0.2s;

  @media screen and (max-width: 980px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    > .title {

      span {
        font-family: Raleway, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;

        color: #2A2A2A;
      }

    }

    > .button {
      margin-left: auto;
    }

  }

  &__nav {
    position: relative;
    width: max-content;

    &-animation {
      content: '';
      position: absolute;

      top: 0;
      left: 2px;

      width: 170px;
      height: 48px;
      border-radius: 100px;
      background: #1454F2;

      z-index: -1;
      transition: all .2s cubic-bezier(0.215, 0.61, 0.355, 1);

      &--right {
        width: 150px;
        left: 54%;

        @media screen and (max-width: 600px) {
          width: 120px !important;
          height: 40px;
        }
        @media screen and (max-width: 420px) {
          width: 105px !important;
        }
      }

      @media screen and (max-width: 600px) {
        width: 140px;
        height: 40px;
      }
      @media screen and (max-width: 420px) {
        width: 116px;
      }
    }

    > button {

      width: 171px;
      height: 48px;

      background: transparent;
      border-radius: 200px;

      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      color: #121212;

      cursor: pointer;

      @media screen and (max-width: 600px) {
        width: 140px;
        height: 40px;
        font-size: 12px;
      }
      @media screen and (max-width: 420px) {
        width: 120px;
      }

      &.active {
        color: #FFFFFF;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

  }

  &__icon-pen {
    position: absolute;
    top: 103px;
    left: 200px;

    @media screen and (max-width: 1800px) {
      display: none;
    }
  }
  &__ellipse-right {
    position: absolute;
    position: absolute;
    right: 0;
    top: 293px;
    z-index: -1;

    @media screen and (max-width: 1800px) {
      display: none;
    }
  }
  &__ellipse-left {
    position: absolute;
    left: 0;
    bottom: 96px;
    z-index: -1;

    @media screen and (max-width: 1800px) {
      display: none;
    }
  }
}
</style>
