<template>
  <div class="course-certificate">
    <div class="course-certificate__title">Сертификат об окончании курса</div>
    <div class="course-certificate__content">
      <div class="course-certificate__label">
        <img :src="imageLabel" alt="">
        <div>{{ label }}</div>
      </div>
      <div class="course-certificate__button">
        <button @click="downloadCertificate" :disabled="!courseCompeted">
          <i v-if="!loading" class="el-icon-download"/>
          <span v-if="!loading">Скачать сертификат</span>
          <i v-else class="el-icon-loading"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {coursesService} from "@/app/user/courses/courses.service";

export default {
  name: "course-certificate",
  props: {
    certificateTemplateId: {type: Number, default: null}
  },
  computed: {
    imageLabel() {
      return this.courseCompeted ? require('@/assets/img/smiles/happy.png') : require('@/assets/img/smiles/thinking.png')
    },
    label() {
      return this.courseCompeted ? 'Поздравляем с прохождением!' : 'Вы еще не прошли курс'
    },
    courseId() {
      return Number(this.$route.params.courseId)
    },
    courseCompeted() {
      return this.$store.state.myCourses.courses.find(course => course.id === this.courseId)?.certificateAvailable
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    downloadCertificate() {
      this.loading = true
      coursesService.downloadCertificate(this.courseId)
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped lang="scss">
.course-certificate {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    text-align: center;

    color: #2A2A2A;
    transition: 0.2s;

    @media screen and (max-width: 980px) {
      font-size: 28px;
    }
    @media screen and (max-width: 500px) {
      font-size: 26px;
    }
    @media screen and (max-width: 450px) {
      font-size: 25px;
    }
    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }

  &__content {
    padding-top: 40px;

    width: max-content;
    margin: 0 auto;

    transition: 0.2s;
    @media screen and (max-width: 450px) {
      padding-top: 25px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    color: #404040;

    transition: 0.2s;
    @media screen and (max-width: 460px) {
      font-size: 16px;
    }
    @media screen and (max-width: 400px) {
      font-size: 15px;
    }

    > img {
      margin-right: 10px;
    }

  }

  &__button {
    margin-top: 20px;
    text-align: center;

    button {
      width: 303px;
      height: 60px;

      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      color: #FFFFFF;

      background: #1454F2;
      border-radius: 50px;

      cursor: pointer;
      transition: 200ms;

      @media screen and (max-width: 500px) {
        width: 280px;
        height: 52px;
      }

      &:hover {
        background: #4b78e9;
      }

      &:disabled {
        cursor: no-drop;

        background: #D6D6D6;
      }

      i {
        font-size: 18px;
        margin-right: 10px;
      }

    }

  }

}
</style>
